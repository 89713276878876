import styled from "styled-components"

export const HeroContainer = styled.div`
  font-family: Roboto;
  position: relative;
  background-color: #000000;
  height: 500px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow-y: hidden;
  margin-top: 100px;
  @media (max-width: 576px) {
    height: 100%;
  }
  .background-image {
    width: 100%;
    min-height: 100%;
    object-fit: cover;
    position: absolute;
  }
  .gatsby-image-wrapper {
    .background-image {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
`

export const BreadCrumbFlex = styled.div`
  position: absolute;
  margin-left: -60px;
  z-index: 100;
  padding: 24px 60px;
  top: 0%;
  left: 50%;
  z-index: 9999;
  width: 100%;
  max-width: 1200px;
  transform: translate(-50%, 0%);
  display: flex;
  align-items: baseline;
  a {
    text-decoration: none;
  }
  img {
    padding: 0 10px;
    transform: scale(80%) translateY(2px);
  }
  span {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    transition: color 200ms ease;
    &:hover {
      color: #DDDDDD;
    }
  }
  @media (max-width: 1400px) {
    margin-left:0;
  }
  @media (max-width: 992px) {
    padding: 24px 40px;

  }
  @media (max-width: 576px) {
    position: relative;
    padding: 20px;
    a span {
      color: #0000dd;
    }
    span {
      color: #fff;
      &:hover {
        color: #00000099;
      }
    }
  }
`

export const TextContentContainer = styled.div`
  position: absolute;
  z-index: 100;
  padding: 24px 60px;
  top: 50%;
  left: 50%;
  z-index: 9999;
  width: 100%;
  max-width: 1200px;
  transform: translate(-50%, -50%);
  @media (max-width: 576px) {
    padding-bottom: 102px;
    position: relative;
    transform: none;
    top: auto;
    left: auto;
    h2 {
      color: ##FFFFFF !important;
    }
  }
`

export const CategoriesFlex = styled.div`
  padding-bottom: 15px;
  font-size: 14px;
  text-align: center;
  font-weight: 900;
  color: #FFFFFF;
  letter-spacing: 2px;
  text-transform: uppercase;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`

export const CategoryTag = styled.div`
  border-radius: 100px;
  font-size: 68px;
  letter-spacing: 2px;
  font-weight: 900;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 50px;
    margin-bottom: 5px;
  }
`

export const Blur = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  transform: translateY(-50%);
  background: #00000066;
`

export const IconImg = styled.div`
  img {
    &:last-child {
      display: none;
    }
  }
`