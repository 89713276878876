import React, { useState, useEffect } from "react"
import { isBrowser } from "@utils/isBrowser"
import { Layout } from "@components/Layout"
import Seo from "../components/Seo/Seo"
import { HeroNewsroomDetail } from "@components/Hero/HeroNewsroomDetail"
import ConversionPanel from "@components/ConversionPanel/ConversionPanel"
import { graphql } from "gatsby"
import { theme } from "@theme"
import { Button } from "@atoms"
import { CardDeck } from "../components/CardDeck"
import { postDetailResolveRichText } from "../utils/resolve-rich-text/blogDetailResolveRichText"
import {
  PostContentGrid,
  PostContentWrapper,
  ScrollBtnWrapper,
  BodyContentWrapper,
  AfterBodyWrapper,
} from "./styles/newsroomDetail.styled"
import MktoForm from "@components/Form/MktoForm"

const headingStyles = {
  padding: "8px 0px",
}

function NewsroomPostTemplate(props) {
  const [showScrollToTop, setShowScrollToTop] = useState(false)
  const postData = props?.data?.contentfulNewsroomPost
  const cardData = props?.data?.allPosts?.edges
  const conversionPanel = props?.data?.contentfulComponentConversionPanel
  const newsroomCardDeck = props?.data?.contentfulComponentCardDeck
  const seo = postData?.pageSeo
  const form = props?.data?.contentfulComponentForm

  useEffect(() => {
    if (!isBrowser()) {
      return null
    }
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowScrollToTop(true)
      } else {
        setShowScrollToTop(false)
      }
    })
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <Layout>
      <Seo
        title={seo?.seoTitle || postData?.title}
        description={seo?.seoDescription}
        noIndex={seo?.noIndex}
        canonical={seo?.canonical}
        image={seo?.graphImage?.file?.url || postData.featuredImage?.file?.url}
        imageAlt={seo?.graphImage?.description}
        metaTags={seo?.metaTags}
        article={seo?.article}
      />
      <HeroNewsroomDetail postData={props?.data?.contentfulNewsroomPost} />
      <PostContentWrapper id="blog_detail_content_wrapper">
        <PostContentGrid>
          <div>
            <BodyContentWrapper theme={theme}>
              {postDetailResolveRichText(postData, headingStyles)}
            </BodyContentWrapper>
          </div>
        </PostContentGrid>

        <ScrollBtnWrapper
          showScrollToTop={showScrollToTop}
          onClick={scrollToTop}
        >
          <Button ctaVariant="secondary">Back To Top</Button>
        </ScrollBtnWrapper>
      </PostContentWrapper>
      {form && (
        <AfterBodyWrapper>
          <MktoForm component={form} />
        </AfterBodyWrapper>
      )}
      {newsroomCardDeck && (
        <CardDeck
          cards={cardData}
          heading={newsroomCardDeck?.heading}
          subheading={newsroomCardDeck?.subheading?.subheading}
          headingSize={newsroomCardDeck?.headingSize}
          kicker={newsroomCardDeck?.kicker}
        />
      )}
      {conversionPanel && <ConversionPanel component={conversionPanel} />}
    </Layout>
  )
}

export default NewsroomPostTemplate

export const NewsroomPostQuery = graphql`
  query NewsroomDetailQuery($id: String) {
    contentfulComponentConversionPanel(
      id: { eq: "793a5931-67fe-5694-b8c6-ee0e447a3d76" }
    ) {
      id
      type
      textAlign
      alignContent
      kicker
      background
      contentful_id
      heading
      headingSize
      internalName
      noTopPadding
      noBottomPadding
      callsToAction {
        contentful_id
        id
        internalLink
        label
        type
        link
        variant
      }
    }
    contentfulPage(internalName: { eq: "Newsroom Detail Template" }) {
      id
      contentful_id
      internalName
    }
    contentfulComponentForm(
      id: { eq: "6f32fbbd-9b15-53f9-9206-fbcc53973a89" }
    ) {
      __typename
      id
      type
      anchorLinkId
      internalName
      marketoFormId
      background
    }
    contentfulComponentCardDeck(
      id: { eq: "326d3a07-b91c-526b-b684-9ffcec7bf1b1" }
    ) {
      __typename
      id
      internalName
      type
      cardType
      backgroundColor
      kicker
      headingSize
      heading
      noTopPadding
      noBottomPadding
      subheading {
        subheading
      }
    }
    contentfulNewsroomPost(id: { eq: $id }) {
      id
      title
      externalTitle
      publishDate
      slug
      pageSeo {
        graphImage {
          description
          title
          file {
            url
          }
        }
        seoTitle
        seoDescription
        canonical
        noIndex
        metaTags
      }
      description {
        description
      }
      category {
        title
        id
      }
      body {
        raw
        references {
          ... on ContentfulComponentVideo {
            id
            caption {
              raw
            }
            alignCaption
            contentful_id
            autoplay
            title
            videoUrl
            videoFile {
              title
              description
              gatsbyImageData
              file {
                url
              }
            }
            internal {
              type
            }
          }
          ... on ContentfulComponentImage {
            id
            internalLink
            url
            caption {
              raw
            }
            alignCaption
            contentful_id
            description
            internal {
              type
            }
            primaryImage {
              gatsbyImageData
              file {
                url
                details {
                  image {
                    height
                    width
                  }
                }
              }
              id
              title
            }
          }
        }
      }
      featuredImage {
        gatsbyImageData
        file {
          url
        }
        title
        description
      }
    }
    allPosts: allContentfulNewsroomPost(
      sort: { fields: publishDate, order: DESC }
      limit: 3
      filter: { id: { ne: $id } }
    ) {
      edges {
        node {
          id
          title
          publishDate
          slug
          description {
            description
          }
          category {
            backgroundColor
            fontColor
            title
            id
          }
          body {
            raw
          }
          featuredImage {
            gatsbyImageData
            file {
              url
            }
          }
        }
      }
    }
  }
`
