import styled from "styled-components"

export const PostContentGrid = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;
  overflow: visible;
  // 16 total for extra flexbility:
  @media (max-width: 991.98px) {
    grid-template-columns: 1fr;
  }
`

export const PostContentWrapper = styled.div`
  white-space: pre-wrap;
  padding: 60px;
  // overflow-x: hidden !important;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 991.98px) {
    padding: 100px 40px;
  }

  @media (max-width: 575.98px) {
    padding: 60px 20px;
  }
`

export const ScrollBtnWrapper = styled.div`
  position: sticky;
  text-align: right;
  bottom: 20px;
  transition: opacity 400ms ease, visibility 400ms ease;
  width: fit-content;
  float: right;
  button {
    transition: filter 200ms ease;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 10px !important;
    line-height: 10px !important;
    padding: 14px 15px !important;
    font-weight: 700;
    min-width: unset !important;
    height: unset !important;
  }
  ${({ showScrollToTop }) =>
    `
    visibility: ${showScrollToTop ? "visible" : "hidden"};
    opacity: ${showScrollToTop ? "100%" : "0%"};
  `}
`

export const BodyContentWrapper = styled.article`
  white-space: normal;
  color: #1b1b1b;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  max-width: 800px;
  @media (max-width: 575.98px) {
    font-size: 16px;
    line-height: 25px;
  }
  ${({ theme }) =>
    `
  font-family: ${theme.font.primary};
`}
`
export const AfterBodyWrapper = styled.article`
  display: grid;
  justify-content: center;
  align-items: center;
  @media (max-width: 991.98px) {
    padding: 60px 40px;
  }
  @media (max-width: 575.98px) {
    padding: 60px 20px;
  }
  @media (min-width: 991.98px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  @media (max-width: 991.98px) {
    margin-top: 50px;
  }
  @media (max-width: 575.98px) {
    margin-top: 25px;
  }
  .subscribe {
    max-width: 500px;
  }
`